import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const useFetchIntercom = () => {
  const { post } = useFetchWithDefaults()
  const { activeWorkspaceId } = useAuth()

  const sendContactRequest = ({ payload }:
  { payload: App.Data.Payloads.Intercom.IntercomContactFormPayload }) => post<void>({
    url: '/v2/system-integrations/intercom/contact-request',
    fetchOptions: {
      body: payload,
    },
    workspaceId: activeWorkspaceId.value ?? undefined,
  })

  const sendCancellation = ({ payload }:
  { payload: App.Data.Payloads.Intercom.IntercomCancelFormPayload }) => post<void>({
    url: '/v2/system-integrations/intercom/cancellation',
    fetchOptions: {
      body: payload,
    },
    workspaceId: activeWorkspaceId.value ?? undefined,
  })

  return {
    sendContactRequest,
    sendCancellation,
  }
}
