import { useFetchIntercom } from './useFetchIntercom'

const sendContactRequest = () => {
  const { sendContactRequest } = useFetchIntercom()

  return useMutation({
    mutationFn: sendContactRequest,
  })
}

const sendCancellation = () => {
  const { sendCancellation } = useFetchIntercom()

  return useMutation({
    mutationFn: sendCancellation,
  })
}

export const useMutationIntercom = {
  sendContactRequest,
  sendCancellation,
}
